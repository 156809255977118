<template>
	<div class="pui-form">
		<mgrupommpp-modals :modelName="modelName"></mgrupommpp-modals>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<pui-field-set class="ml-4 mr-4 mb-4" :title="getTitleGrupommpp">
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<v-row dense>
							<!-- GRMPCODI -->
							<v-col cols="1">
								<pui-text-field
									:id="`grmpcodi-mgrupommpp`"
									v-model="model.grmpcodi"
									:label="$t('mgrupommpp.grmpcodi')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="4"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<!-- GRMPDESC -->
							<v-col cols="4">
								<pui-text-field
									:id="`grmpdesc-mgrupommpp`"
									v-model="model.grmpdesc"
									:label="$t('mgrupommpp.grmpdesc')"
									:disabled="formDisabled"
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<!-- GRMPOBSE -->
							<v-col cols="4">
								<pui-text-field
									:id="`grmpobse-mgrupommpp`"
									v-model="model.grmpobse"
									:label="$t('mgrupommpp.grmpobse')"
									:disabled="formDisabled"
									toplabel
									maxlength="250"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<!-- FECBAJA -->
							<v-col cols="3">
								<pui-date-field
									:id="`fecbaja-mgrupommpp`"
									v-model="model.fecbaja"
									:label="$t('mgrupommpp.fecbaja')"
									:disabled="formDisabled"
									toplabel
									time
								></pui-date-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
			</pui-field-set>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mgrupommppActions from './MgrupommppActions';
import mgrupommppModals from './MgrupommppModals.vue';

export default {
	name: 'mgrupommpp-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mgrupommpp-modals': mgrupommppModals
	},
	data() {
		return {
			modelName: 'mgrupommpp',
			actions: mgrupommppActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		getTitleGrupommpp() {
			return this.$t('form.mgrupommpp');
		}
	},
	created() {}
};
</script>
